@import "../../variables";

.GameResult {
  position: relative;
  width: calc(100% - 32px);
  height: calc(100% - 60px);
  margin: 0;
  padding: 40px 16px 20px;
  overflow: scroll;

  @media screen and (min-device-width: 768px) {
    height: calc(100% - 40px);
    padding: 20px 16px;
  }

  > li:nth-of-type(n + 2) {
    margin: 8px 0 0;
  }

  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 24px;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(51, 57, 70, 0) 0%, #333946 100%);
  }
}
