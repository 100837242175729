@import "../../variables";

.GameResultCard {
  position: relative;
  width: auto;
  height: 38px;
  background: $background-surface;
  border-radius: 9px;
  padding: 8px 16px;
  display: flex;
  flex-flow: row nowrap;

  .Rank {
    flex: 0 0 20px;
    padding: 2px 0;
  }

  .PlayerDetail {
    flex: 1 1 auto;
    margin: 0 0 0 8px;

    .FirstLine {
      display: flex;
      height: 24px;
      flex-flow: row nowrap;
      align-items: center;

      .Title {
        flex: 1 1 auto;
        height: 18px;
        margin: 0 0 0 4px;
        font-weight: 600;
        font-size: 15px;
        line-height: 120%;
        letter-spacing: 0.008em;
        color: #ffffff;
      }

      .Time {
        flex: 0 0 54px;
        height: 14px;
        font-weight: normal;
        font-size: 11px;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.86);
        font-feature-settings: "tnum";
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .Group {
      width: auto;
      height: 13px;
      margin: 1px 0 0;
      font-weight: normal;
      font-size: 11px;
      line-height: 120%;
      color: rgba(255, 255, 255, 0.4);
      display: flex;
    }
  }
}
