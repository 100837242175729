@import "../../variables";

.Game {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .Map {
    position: absolute;
    width: calc(100vw * 3);
    height: calc(100vw * 3);
  }
}
