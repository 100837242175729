@import "../../variables";

.GameResultCarId {
  position: relative;
  width: 18px;
  height: 18px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  .Background {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .CarId {
      width: 18px;
      height: 14px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
    }
  }
}
