@import "variables";

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: $background;
  overflow: hidden;

  .Background {
    .BackgroundParts {
      position: absolute;

      > svg {
        width: 100vw;
        height: calc(100vw * 101 / 375);
      }
    }

    .BackgroundParts:nth-of-type(1) {
      transform: rotate(180deg);
      top: -37px;
      left: 11px;
    }

    .BackgroundParts:nth-of-type(2) {
      transform: rotate(180deg);
      top: -50px;
      left: 75px;
    }

    .BackgroundParts:nth-of-type(3) {
      left: -50px;
      bottom: -18px;
    }

    .BackgroundParts:nth-of-type(4) {
      left: -106px;
      bottom: -42px;
    }
  }
}
