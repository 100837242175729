@import "../../variables";

.GameResultRank {
  position: relative;
  width: 20px;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  > svg {
    width: 16px;
    height: 19px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }

  .Rank,
  .RankWithTrophy {
    color: #ffffff;
    font-style: italic;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Rank {
    width: 20px;
    height: 18px;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.053em;
  }

  .RankWithTrophy {
    width: 16px;
    height: 13px;
    margin: 4px 0 0;
    font-size: 11px;
    line-height: 120%;
  }
}
