@import "../../variables";

.BeforeGame {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  padding: 40px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-device-width: 768px) {
    height: calc(100% - 40px);
    padding: 20px 0;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
}
