body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Kinto Sans", system-ui, -apple-system, "Helvetica Neue",
    "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic UI", "Segoe UI",
    "Meiryo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  display: none;
}
